<!--
 * @Author: yangjinpeng
 * @Date: 2019-06-27 18:29:11
 * @LastEditors: xxx
 * @LastEditTime: 2021-09-29 15:16:17
 -->
<template>
  <div
    :class="{'pltBg' : isPlatform}"
    :style="{
      backgroundColor:(topActive==0?'transparent':'rgba(255, 255, 255, 0.93)'),
      boxShadow:(topActive==0?'0 0px 0px 0 rgba(0,0,0,.07)':'0 2px 10px 0 rgba(0,0,0,.07)')
    }"
    class="page_box jepaas_header"
  >
    <div class="hea_left">
      <img
        v-if="ishome || topActive!=0"
        :src="logof"
        @click="goPage({
          id:1,
          name:'首页',
          active:1,
          pagePath:'/'
        })"
      />
      <img
        v-if="!(ishome || topActive!=0)"
        :src="logor"
        @click="goPage({
          id:1,
          name:'首页',
          active:1,
          pagePath:'/'
        })"
      />
      <span
        @click="goPage({
          id:1,
          name:'首页',
          active:1,
          pagePath:'/'
        })"
        :style="{ color: ishome || topActive!=0?'rgba(246,71,77,1)':'#fff' }"
        class="log_nam"
      >JEPaaS</span>
    </div>
    <!-- 首页第一个swiper添加轮播，除第一个轮播图的时候需要将文字变成白色，同向下滑动样式 -->
    <div class="hea_right">
      <div
        v-for="item in lists"
        :key="item.id"
        @click="goPage(item)"
        :class="{'shop_box': item.name == '商城'}"
      >
        <span
          :style="{ color: ishome || topActive != 0 ? '#3f3f3f' : '#fff' }"
          :class="[
            {'home_activebar': topActive==0 && showhomelink(item)},
            {'act_bor':getCurrent(item) && topActive!=0 || showhomelink(item)},
            {'act_borf':getCurrent(item) && topActive==0 || (showhomelink(item) && topActive == 0)},
          ]"
        >
          <span v-if="item.children && item.children.length > 0" v-popover:product>{{ item.name }}</span>
          <span v-else>
            <i
              v-if="item.name == '商城'"
              :class="[{'shop_activebar': item.name == '商城' && topActive != 0},{'shop_activebar': item.name == '商城' && topActive == 0 && ishome}]"
              class="jeicon jeicon-caigou"
            />
            {{ item.name }}
          </span>
          <el-popover
            ref="product"
            :offset="200"
            :visible-arrow="true"
            placement="bottom"
            popper-class="header_product_popover_wrp"
            trigger="hover"
          >
            <div class="header_product_popover_bar">
              <div
                v-for="(list, li) in lists[1].children"
                :key="li"
                class="header_product_popover_class"
              >
                <div class="header">{{ list.label }}</div>
                <div
                  v-for="(subitem, si) in list.list"
                  :key="si"
                  :class="`${getCurrent(subitem)? 'active': ''}`"
                  @click="goPage(subitem)"
                  class="items"
                >
                  <span>{{ subitem.name }}</span>
                  <span>{{ subitem.subtitle }}</span>
                </div>
              </div>
            </div>
          </el-popover>
        </span>
      </div>
    </div>

    <el-dropdown v-if="isLogin" @command="handleCommand" trigger="click">
      <div>
        <img
          v-if="userInfo.vipState == 1"
          class="userImg_bottom"
          src="../assets/images/VIP_bottom.svg"
        />
        <div
          :style="topActive == 1 && 'background-color: #EFA544'"
          class="userHeader position-relative"
        >
          <img
            v-if="userInfo.photo"
            :src="'/jepaas/document/preview?fileKey=' + userInfo.photo"
            class="userImg"
          />
          <div v-else>
            <div style="width: 100%;height: 100%" class="position-relative">
              <span
                :style="topActive == 1 && 'color: #fff'"
                class="userName font-size-16 color-3F3F3F"
              >{{ userInfo.userName && userInfo.userName.substr(userInfo.userName.length - 2,userInfo.userName.length) }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown" class="dropdown_userInfo">
        <el-dropdown-item disabled command>
          <div style="font-weight: 600;font-size: 18px;color: #3F3F3F; line-height: 2">
            <span class="vertical-align-middle">{{ userInfo.userName }}</span>
            <img
              v-if="userInfo.vipState == 1"
              class="margin-left-10 vertical-align-middle"
              src="../assets/images/VIP.svg"
              alt
            />
          </div>
          <div v-if="userInfo.vipState == 1" style="line-height: 1.2">
            <span class="color-B5B4B4 font-size-12">{{ userInfo.vipTime }}到期</span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item command="WSGRXX">完善个人信息</el-dropdown-item>
        <el-dropdown-item command="WDYG">我的订单</el-dropdown-item>
        <el-dropdown-item command="WDAPP">我的APP</el-dropdown-item>
        <el-dropdown-item command="WDGD">我的工单</el-dropdown-item>
        <el-dropdown-item command="FPGL">发票管理</el-dropdown-item>
        <el-dropdown-item command="HTGL">合同管理</el-dropdown-item>
        <el-dropdown-item command="BTXF">白条消费</el-dropdown-item>
        <el-dropdown-item command="TCDL">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogObj.isShow"
      width="40%"
      custom-class="useExplain_Task"
    >
      <div
        slot="title"
        class="useTitle"
      >
        消息提示
      </div>
      <div
        style="line-height: 2"
        class="color-3F3F3F font-size-18 padding-bottom-30 padding-top-10"
      >
        <div class="text-status-center font-size-20 font-weight-600">
          {{dialogObj.txt}}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script >
import logof from '@/assets/imgs/logof.png'
import logor from '@/assets/imgs/logor.png'
import { removeStorage, setStorage } from '@/util/util'
import { getToken, removeToken } from '@/util/auth'
import { POST_USER_INFO, POST_checkUserInfo } from '@/pages/index/actions/api'

export default {
  nmae: 'Header',
  props: {
    isLoginOrRegister: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      page: 1,
      logof,
      logor,
      lists: [
        {
          id: 1,
          name: '首页',
          active: 1,
          pagePath: '/'
        },
        {
          id: 2,
          name: '平台',
          active: 0,
          pagePath: '/platform'
        },
        {
          id: 4,
          name: '演示',
          active: 0,
          pagePath: '/demonstration'
        },
        {
          id: '4-1',
          name: '大脑',
          active: 0,
          pagePath: '/danaoweb'
        },
        {
          id: 3,
          name: '案例',
          active: 0,
          pagePath: '/successful'
        },
        // {
        //   id: 10,
        //   name: '产品',
        //   active: 0,
        //   pagePath: '/shopCP', // 对产品进行单独 处理
        //   params: {
        //     url: '/shop',
        //     data: 'CP'
        //   }
        // },
        {
          id: 11,
          name: '资讯',
          active: 0,
          pagePath: '/consult', // 咨询
        },
        {
          id: 8,
          name: '下载',
          active: 0,
          pagePath: '/download'
        },
        {
          id: 5,
          name: '学院',
          active: 0,
          pagePath: '/school'
        },
        {
          id: '5-1',
          name: '教程',
          active: 0,
          pagePath: '/videoCourse'
        },
        {
          id: 6,
          name: '商城',
          active: 0,
          pagePath: '/shop'
        },
        {
          id: 9,
          name: '关于',
          active: 0,
          pagePath: '/about'
        }
      ],
      topActive: 0,
      userInfo: {},
      isLogin: false,
      VIP_CODE_show: false,
      dialogObj: {
        isShow: false,
        txt: ''
      }
    }
  },
  computed: {
    getCurrent() {
      return function (item) {
        if (item.pagePath == '/shopCP' && this.$route.query.data) {
          return true
        }
        if (!this.$route.query.data) {
          return (
            (item.pagePath !== '/' &&
              this.$route.path.indexOf(item.pagePath) > -1) ||
            item.name == this.isLoginOrRegister
          )
        }
      }
    },
    showhomelink() {
      return function (item) {
        return this.$route.name === 'home' && item.pagePath === '/'
      }
    },
    ishome() {
      return this.$route.name === 'home'
    },
    isPlatform() {
      return this.$route.name === 'platformPage'
    }
  },
  watch: {
    '$store.state.headerTop': function (nv) {
      console.log()
      if (this.$route.path != '/') {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        scrollTop >= 100
          ? this.$store.commit('topChange', 1)
          : this.$store.commit('topChange', 0)
      }
      this.topActive = nv
    },
    '$store.state.loginStatus': function (nv) {
      this.isLogin = nv
      this.userInfo = this.$store.state.userInfo
      if (nv) {
        const arr = this.lists.filter((item) => item.name == '登录')
        if (arr.length > 0) {
          this.lists.pop()
        }
      } else {
        this.lists.push({
          name: '登录',
          active: 0,
          pagePath: '/login'
        })
      }
    }
  },
  mounted() {
    this.scrollWatch()

    this.isLogin = getToken()
    if (!this.isLogin) {
      this.lists.push({
        name: '登录',
        active: 0,
        pagePath: '/login'
      })
    } else {
      this.getUserInfo()
    }
  },
  created() {
    const self = this
    self.page = self.$store.state.pageActive
    self.lists.map((item) =>
      item.id == self.page ? (item.active = 1) : (item.active = 0)
    )
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 过滤一下会员的过期时间
    filterVipDate(time) {
      time.substring(0, 10)
    },
    // 获取用户登录信息
    getUserInfo() {
      this.$ajax({
        url: POST_USER_INFO
      }).then((datas) => {
        if (!datas.data.success) {
          if (datas.data.errorCode == '9101') {
            removeToken()
            return this.$message({
              message: '用户信息已失效，请重新登录！',
              type: 'error'
            })
          }
          this.isLogin = false
          this.$router.push({ path: '/login' })
        }
        datas.data.obj.vipState == '1' &&
          (datas.data.obj.vipTime = datas.data.obj.vipTime.substring(0, 10))
        const photoArr = datas.data.obj.photo.split('*')
        datas.data.obj.photo = photoArr[1]
        this.$store.commit('getUserinfo', datas.data.obj)
        setStorage('local', 'userInfo', datas.data.obj)
        this.userInfo = datas.data.obj
        setTimeout(() => {
          setStorage('local', 'jepaasStore', this.$store.state)
        })
      })
    },
    // 头像下拉选中触发事件
    handleCommand(command) {
      switch (command) {
        case 'WSGRXX': // 完善个人信息
          this.$router.push({
            path: '/modifyInfo'
          })
          break
        case 'WDYG': // 我的订单
          this.$router.push({
            path: '/alreadyList'
          })
          break
        case 'WDGD': // 我的工单
          this.$ajax({
            url: POST_checkUserInfo,
            data: {
              userId: this.userInfo.userId,
            },
          }).then((datas) => {
            if (datas.data.success) {
              this.$router.push({
                path: '/mytask'
              })
             } else {
               this.dialogObj.isShow = true
               this.dialogObj.txt = datas.data.message
            }
          })
  
          break
        case 'WDAPP': // 我的APP
          this.$router.push({
            path: '/myApp'
          })
          break
        case 'FPGL':
          this.$router.push({
            path: '/order/invoice/indexInvoice'
          })
          break
        case 'HTGL':
          this.$router.push({
            path: '/order/contract/indexContract'
          })
          break
        case 'BTXF': // 白条消费
          this.$router.push({
            path: '/whiteBarPay'
          })
          break
        case 'TCDL':
          // 此处可以处理清空本地缓存等等逻辑
          removeToken()
          removeStorage('local', 'userInfo')
          removeStorage('local', 'jepaasStore')
          // store中登录状态 置为false
          this.isLogin = false
          this.$store.commit('hasLogin', false)
          this.$store.commit('getUserinfo', {})
          // 指定的页面进行退出登录后返回首页
          ;[
            'alreadyListPage',
            'modifyInfoPage',
            'invoicePage',
            'invoiceDetailsPage',
            'indexInvoicePage',
            'invoiceApplyPage',
            'invoiceFormPage',
            'contractPage',
            'indexContractPage',
            'contractApplyPage',
            'contractFormPage',
            'contractDetailsPage',
            'certificatePage',
            'whiteBarPage',
            'paySuccessPage',
            'payFailurePage',
            'myTaskPage',
            'myAppPage'
          ].includes(this.$router.history.current.name) &&
            this.$router.push({ path: '/login' })
          break
        default:
          break
      }
    },
    // 登陆成功监听
    loginSuccess() {
      this.userInfo = this.$store.state.userInfo
    },
    scrollWatch() {
      window.addEventListener('scroll', this.handleScroll)
    },
    goPage(e) {
      if (e.pagePath == '/consult') {
        window.location.href = 'https://jepaas.com/jepaas/mallInformation/initialization.htm';
        return
      }   
      if (e.pagePath == '/product') return
      if (e.pagePath == '/danaoweb') {
        window.open('http://www.jedanao.com/')
        return
      }
      const self = this
      if (e.pagePath != '') {
        // 处理登录注册等多页面page
        if (e.pageType) {
          window.location.href = e.pagePath
        } else {
          self.$router.push({
            path: (e.params && e.params.url) || e.pagePath, // 对产品跳转单独处理
            query: e.params || '' // 产品会传递参数
          })
        }
      }
    },
    goPagec(e) {
      const self = this
      self.$router.push({
        path: e
      })
    },
    openPage(item) {
      window.open(
        `${window.location.origin}${item.pagePath}${window.location.search}`
      )
    },
    handleScroll() {
      // 如果是平台页面  则不改变头背景
      if (this.$route.name == 'platformPage') {
        return
      }
      const self = this
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      scrollTop >= 100
        ? self.$store.commit('topChange', 1)
        : self.$store.commit('topChange', 0)
    }
  }
}
</script>

<style lang='less' scoped >
@import '../pages/index/style/shop/util';
.page_box {
  width: 80%;
  padding: 0 10%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  .hea_left {
    flex: 1;
    position: relative;
    img {
      width: 65px;
      height: 60px;
    }
    .log_nam {
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -19px;
      left: 70px;
      font-size: 30px;
      font-weight: bold;
    }
  }
  .hea_right {
    display: flex;
    align-items: center;
    & > div {
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: #3f3f3f;
      width: 70px;
      .act_bor {
        // padding:6px 15px;
        color: rgba(255, 48, 65, 1) !important;
        // border:2px solid rgba(255,48,65,1);
        // border-radius:20px;
      }
      .act_borf {
        // padding:6px 15px;
        color: rgba(255, 255, 255, 0.5) !important;
        // border:2px solid rgba(255,48,65,1);
        // border-radius:20px;
      }
      .home_activebar {
        color: rgba(255, 48, 65, 1) !important;
      }
      .shop_activebar {
        color: #ff3041 !important;
      }
      img {
        width: 20px;
        height: 23px;
        margin-bottom: -5px;
      }
    }
    .shop_box {
      width: 95px;
    }
  }
  .userImg_bottom {
    width: 23px;
    position: absolute;
    bottom: -2px;
    right: -5px;
    z-index: 2;
  }
  .userHeader {
    width: 50px;
    height: 50px;
    font-size: 10px;
    border-radius: 50%;
    background-color: #fff5e8;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    margin-left: 20px;
    .userName {
      position: relative;
      z-index: 1;
    }
  }
}
.jepaas_bgwhite {
  background-color: rgba(255, 255, 255, 0.93) !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07) !important;
}
.pltBg {
  background: linear-gradient(
    270deg,
    rgba(252, 103, 81, 1) 0%,
    rgba(246, 71, 77, 1) 100%
  ) !important;
}
</style>

<style lang="less">
.dropdown_userInfo {
  min-width: 150px !important;
  .el-dropdown-menu__item {
    line-height: 3;
  }
}
.userImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.header_product_popover_wrp {
  .header_product_popover_bar {
    width: 650px;
    display: flex;
    padding: 30px 20px;
    box-sizing: border-box;
    left: 1041px;
    .header_product_popover_class {
      width: 50%;
      padding-left: 40px;
      .header {
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid #cccccc;
        padding-bottom: 12px;
        width: 220px;
      }
      .items {
        width: 220px;
        margin-top: 20px;
        cursor: pointer;
        > span {
          font-size: 18px;
          color: #666666;
          display: inline-block;
          &:first-child {
            width: 75px;
          }
        }
        &.active,
        &:hover {
          span {
            color: rgba(255, 48, 65, 1);
          }
        }
      }
    }
  }
  .popper__arrow {
    border: 10px solid transparent !important;
    top: -19px !important;
    border-bottom-color: #ebeef5 !important;
    &:after {
      border: 9px solid transparent !important;
      top: -8px !important;
      left: -3px !important;
      border-bottom-color: #ffffff !important;
    }
  }
}

.jepaas_header {
  .useExplain_Task {
    width: 640px !important;
    border-radius: 20px !important;
    padding: 0!important;
    .el-dialog__header {
      padding: 0!important;
    }
    .useTitle {
      display: block;
      padding: 20px;
      box-sizing: border-box;
      color: #ffffff;
      height: 100px;
      background: url('../pages/detail/assets/images/zs/service.png') !important;
      background-size: 100% 100% !important;
      background-repeat: no-repeat !important;
    }
    .el-dialog__headerbtn .el-icon-close{
      font-size: 25px;
      color: #ffffff!important;
    }
    .el-dialog__title {
      font-size: 22px !important;
      font-weight: 600 !important;
    }
    .el-dialog__body {
      font-size: 20px;
      font-weight: 400;
      color: rgba(63, 63, 63, 1);
      padding-top: 10px;
      padding-bottom: 0;
    }
  }
}
</style>
